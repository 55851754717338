<template>
  <div class="nav">
    <div
      class="nav-item"
      :class="{ current: index === current }"
      v-for="(item, index) in list"
      :key="index"
      @click="current = index"
    >
      {{ item.name }}
    </div>
  </div>
  <div class="main">
    <div class="list">
      <div
        class="list-item wow fadeInUp"
        v-for="(item, index) in currentListPage"
        :key="index"
      >
        <div class="item-image" :style="`background-image:url(${item.image});`">
          <!--          <img :src="item.image" />-->
        </div>
        <div class="item-content">
          <div class="content-title">
            {{ item.title }}
          </div>
          <div class="content-date">
            {{ item.date }}
          </div>
          <div class="content-info">
            {{ item.info }}
          </div>
          <div class="content-more" @click="goNews(item.href)">
            <!--            <a :href="item.href" target="_blank">-->
            更多
            <!--            </a>-->
            <img
              class="more-icon1"
              :src="require('@/assets/news/list/icon1.png')"
            />
            <img
              class="more-icon"
              :src="require('@/assets/news/list/icon.png')"
            />
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      class="page"
      background
      layout="prev, pager, next"
      :page-size="pageSize"
      v-model:current-page="currentPage"
      :total="currentList.length"
    />
  </div>
</template>
<script>
import { ElPagination } from "element-plus";
// import newsData from "newsData";
import axios from "axios";
export default {
  components: {
    ElPagination,
  },
  computed: {
    currentList() {
      let { list, current } = this;
      return list[current].child;
    },
    currentListPage() {
      let { currentList, currentPage, pageSize } = this;
      let s = (currentPage - 1) * pageSize;
      let e = currentPage * pageSize;
      return currentList.slice(s, e);
    },
  },
  data() {
    return {
      current: 0,
      list: [
        {
          name: "公司新闻",
          child: [],
        },
        {
          name: "案例分享",
          child: [],
        },
        {
          name: "行业动态",
          child: [],
        },
      ],
      currentPage: 1,
      pageSize: 3,
    };
  },
  watch: {
    current() {
      this.currentPage = 1;
    },
  },
  methods: {
    getData(url, index) {
      axios.get(url).then((res) => {
        let objE = document.createElement("div");
        objE.innerHTML = res.data;
        let doms = objE.querySelectorAll(".elementor-widget-container");
        // console.log(doms);
        let d = [];
        for (let i = 0, len = doms.length; i < len; i++) {
          let dom = doms[i];
          let imgSrc = dom.getElementsByTagName("img")[0]?.src;
          let title = dom.getElementsByTagName("h1")[0]?.innerText;
          let info = dom.getElementsByTagName("h2")[0]?.innerText;
          let href = dom.getElementsByTagName("h3")[0]?.innerText;
          let date = dom.getElementsByTagName("h4")[0]?.innerText;
          // console.log(imgSrc, title, info, href);
          d.push({
            title: title,
            info: info,
            date: date,
            image: imgSrc,
            href: href,
          });
        }
        this.list[index].child = d;
      });
    },
    goNews(url) {
      this.$router.push({
        path: "/child",
        query: {
          url: url,
        },
      });
    },
  },
  mounted() {
    this.getData(`/news/6666-2/?timestamp=${new Date().getTime()}`, 0);
    this.getData(`/news/clothing-case/?timestamp=${new Date().getTime()}`, 1);
    this.getData(
      `/news/industry-dynamics/?timestamp=${new Date().getTime()}`,
      2
    );
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .nav {
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      height: 2.5rem;
      ling-height: 2.5rem;
    } @else {
      height: 0.9rem;
      ling-height: 0.9rem;
      margin-bottom: 0.1rem;
    }
    .nav-item {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #95a5a4;
      letter-spacing: 0.01rem;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        margin: 0 0.85rem;
      } @else {
        font-size: 0.2rem;
        margin: 0 0.55rem;
      }
      &.current {
        color: #333333;
        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      }
      &.current:before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background: #5ec7b9;
        @if ($searchWidth<1100) {
          width: 2rem;
          height: 0.2rem;
        } @else {
          width: 0.9rem;
          height: 0.04rem;
        }
      }
    }
  }
  .main {
    background-image: url("~@/assets/news/list/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .list {
      margin: auto;
      @if ($searchWidth<1100) {
        padding: 0.7rem 0.5rem 1rem;
        width: 100%;
        box-sizing: border-box;
      } @else {
        width: 13rem;
        padding: 0.4rem 0 1rem;
      }
      .list-item {
        background: #ffffff;
        transition: all 0.2s;
        display: flex;
        @if ($searchWidth<1100) {
          flex-direction: column;
          width: 100%;
          margin-top: 1.5rem;
        } @else {
          margin-top: 0.3rem;
          height: 3.5rem;
        }
        a {
          text-decoration: none;
          &:visited {
            color: #5ec7b9;
          }
        }
        &:hover {
          background: #ffffff;
          box-shadow: 0px 3px 11px 3px rgba(69, 137, 128, 0.2);
          .content-title,
          .content-info,
          .content-date,
          .content-more {
            cursor: pointer;
          }
          //.content-more {
          //  .more-icon {
          //    display: none;
          //  }
          //  .more-icon1 {
          //    display: block;
          //  }
          //}
        }
        .item-image {
          flex-shrink: 0;
          box-sizing: border-box;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          @if ($searchWidth<1100) {
            width: 100%;
            height: 11.5rem;
          } @else {
            width: 6rem;
          }
          //img {
          //  width: 100%;
          //  display: block;
          //}
        }
        .item-content {
          box-sizing: border-box;
          @if ($searchWidth<1100) {
            padding: 1rem 1.4rem 1rem 1rem;
          } @else {
            display: flex;
            flex-direction: column;
            padding: 0.54rem 0.34rem 0.6rem 0.67rem;
          }
        }

        .content-title,
        .content-info,
        .content-date,
        .content-more {
          transition: all 0.2s;
        }
        .content-title {
          font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
          color: #333333;
          @if ($searchWidth<1100) {
            font-size: 1.2rem;
          } @else {
            font-size: 0.24rem;
          }
        }
        .content-info {
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @if ($searchWidth<1100) {
            font-size: 0.8rem;
          } @else {
            font-size: 0.16rem;
            line-height: 0.27rem;
          }
        }
        .content-date {
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          color: #7a7a7a;
          @if ($searchWidth<1100) {
            font-size: 0.8rem;
            line-height: 1;
            margin: 0.5rem 0 1rem;
          } @else {
            font-size: 0.16rem;
            line-height: 0.27rem;
            margin: 0.1rem 0 0.25rem;
          }
        }
        .content-more {
          font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
          color: #5ec7b9;
          display: flex;
          align-items: center;
          @if ($searchWidth<1100) {
            font-size: 0.8rem;
            margin-top: 0.65rem;
            img {
              width: 0.7rem;
              margin-left: 0.25rem;
            }
          } @else {
            margin-top: auto;
            font-size: 0.16rem;
            img {
              width: 0.14rem;
              margin-left: 0.05rem;
            }
          }

          .more-icon1 {
            display: none;
          }
          .more-icon {
            display: block;
          }
        }
      }
    }
  }
  .page::v-deep {
    .el-pager {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      .is-active {
        background-color: #5ec7b9 !important;
        color: #ffffff;
      }
      .number {
        color: #7a7a7a;
        font-size: 16px;
        font-weight: normal !important;
      }
    }
    .btn-next,
    .btn-prev {
      i {
        font-size: 16px !important;
      }
    }
    .btn-next:hover:not(:disabled),
    .btn-prev:hover:not(:disabled) {
      background-color: #5ec7b9 !important;

      i {
        color: #fff !important;
      }
    }
  }
}
@media (max-width: 1100px) {
  @include searchWidth(999);
}
@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
